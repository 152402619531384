import { conditionalSpread } from 'clyne-core';
import React from 'react';

import exampleImage from './media/exmaple.jpg';

const news = {
    intro: {
        title: 'News',
    },
    list: [
        // sample, paste new NEWS above this 
        ...conditionalSpread([
            {
                image: exampleImage,
                slug: 'the-evolution-of-quantum-chemistry',
                title: 'The Evolution of Quantum Chemistry',
                // abstract that gets picked up and displayed on homepage
                card: 'Our cutting-edge solutions focus on efficiency and decarbonization efforts in the oil & gas industry through novel AI applications and improved accuracy wherever sensors are used.',
                article: {
                    children: (
                        <>
                            <h1>Heading 1</h1>
                            <p>Our cutting-edge solutions focus on efficiency and decarbonization efforts in the oil & gas industry through novel AI applications and improved accuracy wherever sensors are used.</p>
                            <img src={exampleImage} alt='' />
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis condimentum urna. Praesent at ex mauris. Proin risus tellus, vestibulum nec augue et, ultrices consequat est. Nulla ut nulla blandit neque venenatis blandit. Duis vel tortor non nulla condimentum pharetra vitae non nibh. Morbi molestie dignissim risus sit amet porta. Nam lorem velit, tempus in eleifend in, lobortis venenatis dolor. Aenean ornare tortor at nibh laoreet, eu vestibulum nulla egestas. Morbi eget ante sed purus semper feugiat eu sit amet lacus. Duis faucibus nulla ex, in fermentum magna suscipit et. In at condimentum sem.</p>
                            <p>Nam tincidunt tortor a quam euismod, ac aliquet nisl convallis. Nunc at massa convallis, vulputate ipsum eu, fringilla nisi. Sed accumsan pulvinar vulputate. Nunc neque elit, posuere sed faucibus a, luctus vel massa. Vestibulum id convallis tellus, ut facilisis ipsum. Sed non nibh lorem. Nullam faucibus eleifend dui sed lacinia. Quisque vel elit sollicitudin, varius neque eu, lobortis purus. Proin imperdiet pretium pellentesque. Morbi id augue justo. Proin sodales nec purus quis tincidunt. Duis dapibus laoreet est, et bibendum enim. Vivamus facilisis leo rhoncus urna accumsan, nec fringilla purus volutpat.</p>
                            <h2>Heading 2</h2>
                            <p>Duis arcu ipsum, iaculis ac dictum nec, tempor quis risus. Praesent bibendum sem mauris, aliquam posuere urna feugiat nec. Nunc bibendum neque ut fringilla pellentesque. Nullam a neque risus. Aenean venenatis consequat justo, non volutpat neque maximus sit amet. Proin at diam tincidunt, viverra felis ac, elementum nunc. Nullam sed porttitor sem. Etiam mollis metus volutpat nulla accumsan, vel porta sapien laoreet.</p>
                            <p>Phasellus maximus suscipit lectus, non elementum dui. Praesent quis enim pharetra, fringilla diam vitae, bibendum justo. Ut finibus volutpat lectus eu ornare. Fusce posuere urna nec finibus facilisis. Integer maximus, eros blandit ultricies interdum, lorem arcu bibendum elit, ac varius neque felis et elit. Suspendisse ultrices turpis in sem scelerisque mattis. Duis malesuada metus at lobortis hendrerit. Proin ac arcu rhoncus, accumsan diam eget, ultricies metus. Nulla sed scelerisque magna.</p>
                            <h3>Heading 3</h3>
                            <p>Phasellus nec magna ac ipsum ultrices fermentum. Vestibulum maximus sed turpis vel sollicitudin. Aenean arcu sem, varius ac augue eu, hendrerit placerat velit. Sed convallis in lacus ut consequat. Vivamus dolor metus, mollis id leo ac, dapibus ultrices orci. Nullam id magna vitae arcu iaculis suscipit non sed elit. Sed sagittis magna ultrices pretium sagittis. Aenean suscipit ante molestie justo commodo condimentum.</p>
                            <h4>Heading 4</h4>
                            <ul>
                                <li>hello</li>
                                <li>hello</li>
                                <li>hello</li>
                                <li>hello</li>
                                <li>hello</li>
                            </ul>
                            <h5>Heading 5</h5>
                            <ol>
                                <li>hello</li>
                                <li>hello</li>
                                <li>hello</li>
                                <li>hello</li>
                                <li>hello</li>
                            </ol>
                            <h6>Heading 6</h6>
                        </>
                    ),
                },
            },
        ], process.env.NODE_ENV === 'development')
    ],
};

export default news;
