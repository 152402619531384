import React from 'react';

const services = [
    {
        icon: 'icon-processor',
        slug: 'ai-and-quantum-technology',
        title: (
            <>
                AI & Quantum{` `}<br />
                Technology
            </>
        ),
        card: 'Our cutting-edge solutions focus on efficiency and decarbonization efforts in the oil & gas industry through novel AI applications and improved accuracy wherever sensors are used.',
        /*article: {
            children: (
                <>
                    <p>Our cutting-edge solutions focus on efficiency and decarbonization efforts in the oil & gas industry through novel AI applications and improved accuracy wherever sensors are used.</p>
                </>
            ),
        },*/
    },
    {
        icon: 'icon-clipboard-plus',
        slug: 'precision-medicine-diagnostics',
        title: (
            <>
                Precision Medicine{` `}<br />
                & Diagnostics
            </>
        ),
        card: 'We specialize in AI solutions for disease detection and diagnosis in precision medicine, utilizing pinpoint application of AI and research into quantum sensors we are breaking new ground in the biomedical sciences.',
        /*article: {
            children: (
                <>
                    <p>We specialize in AI solutions for disease detection and diagnosis in precision medicine, utilizing pinpoint application of AI and research into quantum sensors we are breaking new ground in the biomedical sciences.</p>
                </>
            ),
        },*/
    },
    {
        icon: 'icon-shield-check',
        slug: 'post-quantum-encryption',
        title: (
            <>
                Post-Quantum{` `}<br />
                Encryption
            </>
        ),
        card: 'We bring understanding and awareness, as well as unparalleled discretion, to help businesses through a difficult transition.',
        /*article: {
            children: (
                <>
                    <p>We bring understanding and awareness, as well as unparalleled discretion, to help businesses through a difficult transition.</p>
                </>
            ),
        },*/
    },
].map(item => ({
    ...item,
    parent: 'services',
}));

export default services;
